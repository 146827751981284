
import Button from '@/components/Button.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { emailRegisterSchema, useRegister } from '@/pages/register/register'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'RegisterWithEmail',
  components: { GoogleLoginBtn, Button, ThePasswordInput, FormInput, Form, PageWithHeader },
  setup () {
    const form = shallowRef()
    const [register, progress] = useRegister(true)
    const islamicCheck = shallowRef(false)
    const isIslamic = shallowRef(useRoute().query.isIslamic)
    const countryCode = shallowRef(useRoute().query.countryCode)

    return {
      form,
      emailRegisterSchema,
      islamicCheck,
      isIslamic,
      submit () {
        if (form.value) {
          form.value.values.countryCode = countryCode.value
          if (isIslamic.value) {
            form.value.values.islamFlag = Number(islamicCheck.value)
          }
        }
        (form.value as HTMLFormElement).submit()
      },
      register,
      progress,
    }
  },
})
