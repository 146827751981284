/**
* @author ayou[beica1@outook.com]
* @date 2022/1/27 15:47
* @description
*   RegisterWithEmail.vue of WeTrade
*/
<template>
<PageWithHeader class="px-16">
  <template #title>
    <t path="signupemail_2"></t>
  </template>
  <t custom #="{t}">
    <Form ref="form" @submit="register" :schema="emailRegisterSchema" class="p-16">
      <FormInput name="email" :placeholder="t('signupemail_3')" />
      <ThePasswordInput :placeholder="t('signupemail_4')" />
    </Form>
  </t>
  <template #footer>
    <div v-if="isIslamic" class="islamic mb-16 df-middle" @click="() => islamicCheck = !islamicCheck">
      <div class="checkbox br-sm mr-8" :class="{'active': islamicCheck}"></div>
      <t path="signinup_24" class="f-md">Islamic Account  (Swap Free)</t>
    </div>
    <t #="{td, t}" custom>
      <p class="f-sm term c-title">
        {{t('signinup_10')}}
        <router-link to="/common/link?url=/terms_of_use.html&title=Terms"
          class="c-primary f-medium">
          {{t('signinup_11') || 'Term & Conditions'}}
        </router-link>
        {{t('signinup_12')}}
        <router-link to="/common/link?url=/privacy_policy.html&title=Policy"
          class="c-primary f-medium">
          {{t('signinup_13') || 'Privacy Policy'}}
        </router-link>
      </p>
    </t>
    <Button class="mt-24 primary br-lg f-bold f-lg" :progress="progress" @click="submit">
      <t path="signupemail_9"></t>
    </Button>
    <GoogleLoginBtn class="br-lg" />
    <div class="wedge" style="height: 40px"></div>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { emailRegisterSchema, useRegister } from '@/pages/register/register'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'RegisterWithEmail',
  components: { GoogleLoginBtn, Button, ThePasswordInput, FormInput, Form, PageWithHeader },
  setup () {
    const form = shallowRef()
    const [register, progress] = useRegister(true)
    const islamicCheck = shallowRef(false)
    const isIslamic = shallowRef(useRoute().query.isIslamic)
    const countryCode = shallowRef(useRoute().query.countryCode)

    return {
      form,
      emailRegisterSchema,
      islamicCheck,
      isIslamic,
      submit () {
        if (form.value) {
          form.value.values.countryCode = countryCode.value
          if (isIslamic.value) {
            form.value.values.islamFlag = Number(islamicCheck.value)
          }
        }
        (form.value as HTMLFormElement).submit()
      },
      register,
      progress,
    }
  },
})
</script>

<style scoped lang="scss">
.checkbox{
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-border);
  &.active{
    background-color: var(--color-primary);
    border: none;
    position: relative;
  }
  &.active::after{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(/img/login/check@2x.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    content: '';
  }
}
</style>
